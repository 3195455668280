'use client';

import { useState } from 'react';
import Button from '@/components/Button';
import { Input } from '@/components/Input';
import { useUtmParams } from '@/lib/useUtmParams';
import { utmFields } from '@/lib/pardot-tracking-fields';

type NoEventsProps = {
  filters: {
    inPerson: boolean;
    online: boolean;
  };
};

export default function NoEvents({ filters }: NoEventsProps) {
  const [emailError, setEmailError] = useState<string | null>(null);
  const utmParams = useUtmParams();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const form = e.currentTarget;
    const formData = new FormData(form);

    // verify email is correct format
    const email = formData.get('email') as string;
    if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
      setEmailError('Please enter a valid email address.');
      e.preventDefault();
      return;
    }
  };

  const getInPersonOrOnline = () => {
    if (filters.inPerson && filters.online) {
      return '';
    }
    if (filters.inPerson) {
      return 'in-person ';
    }
    if (filters.online) {
      return 'online ';
    }
    return '';
  };

  return (
    <div className="flex w-full flex-col items-center rounded-2xl bg-[#202328] p-4 md:p-8">
      <div className="flex flex-col items-center">
        <div className="mb-8 flex max-w-prose flex-col text-center">
          <h4 className="text-h6 mb-4">{`There are no upcoming ${getInPersonOrOnline()}events`}</h4>
          <p className="text-body text-gray-4">
            Subscribe to the DPE Newsletter to get notified of new events, plus get monthly roundups of industry and
            community news, expert takes, and job openings.
          </p>
        </div>
        <form
          className="flex w-full max-w-sm flex-col gap-4"
          onSubmit={handleSubmit}
          action="https://go.gradle.com/l/68052/2024-12-12/n3vbp8"
        >
          <Input required label="Email" name="email" type="email" />
          {utmFields.map(key => (
            <input key={key} type="hidden" id={key} name={key} value={utmParams[key]} />
          ))}
          {emailError && <p className="text-[#ff0000]">{emailError}</p>}
          <Button variant="primary">Sign Up</Button>
        </form>
      </div>
    </div>
  );
}
