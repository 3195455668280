'use client';

import { useState, useMemo } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';

import EventCard from '../EventCard';
import { type Event } from '../events';
import DropdownSelect from '@/components/DropdownWithCheckboxes';

export function PastEvents({ events }: { events: Event[] }) {
  const [endIdx, setEndIdx] = useState(6);
  const [filterArray, setFilterArray] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState('');

  const filterTopicsArray = [
    'Developer productivity',
    'Acceleration',
    'Troubleshooting',
    'Metrics',
    'Gradle Build Tool',
    'Apache Maven',
    'Bazel',
    'sbt',
    'Android',
  ];

  const loadMoreIncrement = 6;

  const handleLoadMore = () => {
    setEndIdx(prev => prev + loadMoreIncrement);
  };

  const handleLoadLess = () => {
    setEndIdx(loadMoreIncrement);
  };

  const handleSortAndFilter = useMemo(() => {
    let filteredEventDataCopy = events.filter(event => {
      if (filterArray.length === 0) {
        return true;
      }
      return event?.topics?.some(topic => filterArray.includes(topic));
    });

    filteredEventDataCopy = filteredEventDataCopy.filter(event =>
      event.title.toLowerCase().includes(searchQuery.toLowerCase()),
    );

    return filteredEventDataCopy;
  }, [filterArray, searchQuery, events]);

  return (
    <section className="py-section px-section relative bg-light-background-100 px-4 text-white md:px-10" id="past">
      <div className="max-w-section flex flex-col gap-12 md:gap-14 lg:flex-row lg:gap-10">
        <div className="z-10 flex w-full flex-col gap-6">
          <h2 className="text-h4 text-gray-1">Past events</h2>
          <div className="flex flex-col justify-end gap-4 md:flex-row">
            <div className="relative flex w-full items-center justify-center lg:w-80">
              <MagnifyingGlassIcon className="absolute left-4 size-6 text-gradle-blue" />
              <input
                type="text"
                placeholder="Search events"
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                className="w-full rounded-lg border-2 border-light-background-200 bg-white py-4 pl-12 pr-4 text-gray-1 placeholder:text-gray-1 hover:bg-light-background-200"
              />
            </div>
            <DropdownSelect
              options={filterTopicsArray}
              className="rounded-lg bg-white text-gray-1 hover:bg-light-background-200"
              hasClearAll
              isMultiSelect
              placeholder="Filter by topics"
              selectedOptions={filterArray}
              selectionSummary={`Topic${filterArray?.length > 1 ? 's' : ''} selected`}
              setSelectedOptions={setFilterArray}
            />
          </div>
          <div className="flex flex-col gap-4">
            <div className="flex flex-col gap-6 lg:grid lg:grid-cols-3">
              {handleSortAndFilter.slice(0, endIdx).map((event: Event) => (
                <EventCard variant="past" key={event.title} {...event} />
              ))}
            </div>

            {handleSortAndFilter.length < loadMoreIncrement ? null : (
              <div className="self-center">
                {handleSortAndFilter.length - 1 >= endIdx ? (
                  <button
                    onClick={handleLoadMore}
                    className="group flex flex-row items-center justify-center gap-2 p-3 text-gray-1"
                  >
                    Load more{' '}
                    <ChevronDownIcon className="size-5 text-gray-1 transition-all group-hover:translate-y-0.5" />
                  </button>
                ) : (
                  <button
                    onClick={handleLoadLess}
                    className="group flex flex-row items-center justify-center gap-2 p-3 text-gray-1"
                  >
                    Show less{' '}
                    <ChevronUpIcon className="size-5 text-gray-1 transition-all group-hover:-translate-y-0.5" />
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
