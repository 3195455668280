'use client';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
import { eventTypes, type Event } from '../events';
import EventCard from '../EventCard';
import { useState } from 'react';
import NoEvents from './NoEvents';

export default function Upcoming({ events }: { events: Event[] }) {
  const [endIdx, setEndIdx] = useState(6);

  const loadMoreIncrement = 6;

  const [filters, setFilters] = useState({
    inPerson: true,
    online: true,
  });

  const filteredEvents = events.filter(event => {
    if (filters.inPerson && event.eventType === eventTypes.inPerson) return true;
    if (filters.online && event.eventType === eventTypes.online) return true;
    return false;
  });

  const handleFilterChange = (filterType: 'inPerson' | 'online') => {
    // if last filter is unchecked, don't uncheck it
    if (filters[filterType] && Object.values(filters).filter(Boolean).length === 1) {
      return;
    }

    setFilters(prev => {
      return {
        ...prev,
        [filterType]: !prev[filterType],
      };
    });
  };

  const handleLoadMore = () => {
    setEndIdx(prev => prev + loadMoreIncrement);
  };

  const handleLoadLess = () => {
    setEndIdx(loadMoreIncrement);
  };

  return (
    <section className="px-section bg-[#090a0c] pb-16 text-white" id="upcoming">
      <div className="max-w-section flex flex-col">
        <div className="flex flex-col">
          <h2 className="text-h4 mb-8 md:mb-10">Upcoming events</h2>

          <div className="mb-8 flex gap-4 border-b border-[#2E3135] pb-6 md:mb-10 md:pb-10">
            <label htmlFor="filter-by-in-person" className="flex gap-2">
              <input
                type="checkbox"
                id="filter-by-in-person"
                checked={filters.inPerson}
                className="checkbox checkbox-sm rounded-[0.25rem] bg-gray-2"
                onChange={() => handleFilterChange('inPerson')}
              />
              In person
            </label>
            <label htmlFor="filter-by-online" className="flex gap-2">
              <input
                type="checkbox"
                id="filter-by-online"
                checked={filters.online}
                className="checkbox checkbox-sm rounded-[0.25rem] bg-gray-2"
                onChange={() => handleFilterChange('online')}
              />
              Online
            </label>
          </div>
          {filteredEvents.length === 0 ? <NoEvents filters={filters} /> : null}
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-6">
            {filteredEvents.slice(0, endIdx).map((event, idx) => (
              <EventCard key={`${event.title}_${idx}`} {...event} />
            ))}
          </div>
          {filteredEvents.length < loadMoreIncrement ? null : (
            <div className="self-center">
              {filteredEvents.length - 1 >= endIdx ? (
                <button onClick={handleLoadMore} className="group flex flex-row items-center justify-center gap-2 p-3">
                  Load more <ChevronDownIcon className="size-5 transition-all group-hover:translate-y-0.5" />
                </button>
              ) : (
                <button onClick={handleLoadLess} className="group flex flex-row items-center justify-center gap-2 p-3">
                  Show less <ChevronUpIcon className="size-5 transition-all group-hover:-translate-y-0.5" />
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
