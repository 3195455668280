'use client';
import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

interface CustomerCardProps {
  options: string[];
  placeholder: string;
  className?: string;
  isMultiSelect?: boolean;
  hasClearAll?: boolean;
  selectedOptions?: string[];
  selectionSummary?: string;
  setSelectedOptions?: React.Dispatch<React.SetStateAction<string[]>>;
}

const DropdownSelect = ({
  options,
  placeholder,
  className,
  hasClearAll = false,
  isMultiSelect = false,
  selectedOptions,
  selectionSummary,
  setSelectedOptions,
}: CustomerCardProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const ulRef = useRef<HTMLUListElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleOpen = () => {
    setIsOpen(prev => !prev);
  };

  const handleClickOption = (e: React.MouseEvent<HTMLInputElement>) => {
    if (!setSelectedOptions) return;

    if (isMultiSelect) {
      const target = e.target as HTMLInputElement;

      if (target.checked) {
        setSelectedOptions(prev => [...prev, target.id]);
      } else {
        setSelectedOptions(prev => prev.filter(item => item !== target.id));
      }
    } else {
      setSelectedOptions([e.currentTarget.id as string]);
      setIsOpen(false);
    }
  };

  const handleClearAll = () => {
    if (!ulRef.current) return;
    setSelectedOptions?.([]);
    const checkboxes = ulRef.current.querySelectorAll('input[type="checkbox"]') as NodeListOf<HTMLInputElement>;
    checkboxes.forEach(checkbox => {
      checkbox.checked = false;
    });
  };

  useEffect(() => {
    const handleOutSideClick = ({ target }: MouseEvent) => {
      if (!ulRef.current?.contains(target as Node) && !buttonRef.current?.contains(target as Node)) {
        setIsOpen(false);
      }
    };

    window.addEventListener('mousedown', handleOutSideClick);

    return () => {
      window.removeEventListener('mousedown', handleOutSideClick);
    };
  }, [ulRef, buttonRef]);

  return (
    <div className={clsx('text-body relative', className)}>
      <button
        ref={buttonRef}
        tabIndex={0}
        onClick={handleOpen}
        className="flex w-full cursor-pointer items-center justify-between gap-2 rounded-lg border-2 border-light-background-200 p-4 hover:bg-light-background-100 md:w-60 lg:w-60 xl:w-60"
      >
        {selectedOptions?.length === 0 && placeholder}
        {selectedOptions && selectedOptions.length > 0 && (
          <>{isMultiSelect ? `${selectedOptions.length} ${selectionSummary}` : selectedOptions.toString()}</>
        )}
        {isOpen ? <ChevronUpIcon className="size-4" /> : <ChevronDownIcon className="size-4" />}
      </button>
      <ul
        ref={ulRef}
        tabIndex={0}
        className={clsx(
          'absolute left-0 top-16 z-10 flex w-full flex-col gap-2 rounded-lg bg-base-100 p-3 shadow-custom-sm',
          isOpen ? 'flex flex-col' : 'hidden',
        )}
      >
        {options.map((option, idx) => (
          <li key={idx} className="text-gray-2">
            {isMultiSelect ? (
              <label
                className="flex cursor-pointer select-none items-center gap-2 rounded-lg p-1 text-[14px] hover:bg-light-background-100"
                htmlFor={option}
              >
                <input
                  id={option}
                  type="checkbox"
                  className="checkbox checkbox-xs rounded-[0.25rem]"
                  onClick={handleClickOption}
                />

                {option}
              </label>
            ) : (
              <input
                id={option}
                type="button"
                className="flex w-full cursor-pointer select-none items-center rounded-lg p-1 text-[14px] hover:bg-light-background-100"
                value={option}
                onClick={handleClickOption}
              />
            )}
          </li>
        ))}
        {hasClearAll && (
          <li>
            <hr className="text-gray-5" />
            <button onClick={handleClearAll} className="mt-2 w-full rounded-lg p-1 hover:bg-light-background-100">
              Clear all
            </button>
          </li>
        )}
      </ul>
    </div>
  );
};

export default DropdownSelect;
